<template>
  <header class="header-container d-flex align-items-center mt-3">
    <img class="clube-logo" src="@/assets/images/logo-clube-do-assinante.png" alt="">
    <img class="tribuna-logo ml-3 d-none d-sm-block" src="@/assets/images/logo-jornal.png" alt="A Tribuna logo">
  </header>
</template>

<script>
export default {
  name: 'VClubHeader'
}
</script>

<style lang="scss" scoped>
.clube-logo {
  width: 104px;
}
.tribuna-logo {
  width: 120px;
  mix-blend-mode: luminosity;
  opacity: 0.3;
}
</style>
