<template>
  <div class="club">
    <section class="pb-5">
      <div class="container">
        <VClubHeader/>
        <div class="d-flex justify-content-between align-items-center">
          <VOfferBanner
            class="offer-banner"
            :offer="offer.title"
            :seller="offer.seller"
          />
          <VClubCTA/>
        </div>
      </div>
    </section>
    <section class="cards-container pt-5">
      <div class="container">
        <VSearchBar class="search-bar"/>
        <div class="row mt-5">
          <div v-for="(item, k) in Array(15)" :key="k" class="col-lg-4 col-xl-3 col-md-6 mb-4">
            <VClubCard/>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VClubHeader from '../modules/club/components/VClubHeader'
import VClubCTA from '../modules/club/components/VClubCTA'
import VOfferBanner from '../modules/club/components/VOfferBanner'
import VSearchBar from '../modules/club/components/VSearchBar'
import VClubCard from '../modules/club/components/VClubCard'

export default {
  components: { VClubHeader, VClubCTA, VOfferBanner, VSearchBar, VClubCard },
  data () {
    return {
      offer: {
        title: '30% de desconto na caixa de Heineken',
        seller: 'ExtraPlus'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cards-container {
  width: 100% ;
  background-color: #ffffff;
  .search-bar {
    margin-top: -74px;
  }
}
</style>
