<template>
  <div class="offer-container">
    <h3>{{ seller }}</h3>
    <h2>{{ offer }}</h2>
  </div>
</template>

<script>
export default {
  name: 'VOfferBanner',
  props: {
    offer: String,
    seller: String
  }
}
</script>

<style lang="scss" scoped>
.offer-container {
  max-width: 35vw;
  @media (max-width: 768px) {
    max-width: 100vw;
    margin: 60px 0;
  }
  @media (min-width: 769px) and (max-width: 1204px) {
    max-width: 50vw;
  }
  h3 {
    font-size: 24px;
    color: $pink;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  h2 {
    font-size: 50px;
    font-weight: 700;
    color: $dark-blue;
    @media (max-width: 768px) {
      font-size: 26px;
    }
  }
}
</style>
