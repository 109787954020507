<template>
  <div class="search-bar d-flex justify-content-between align-items-center">
    <div class="d-flex flex-grow-1 justify-content-between align-items-center">
      <span @click="$refs['search-input'].focus()" class="icon text-muted fal fa-search p-3"></span>
      <input ref="search-input" class="py-2" type="text" placeholder="pesquisar...">
      <span class="pipe d-none d-md-inline"></span>
    </div>
    <select class="d-none d-md-block py-3 pr-2 mr-2">
      <option v-for="option in options" :key="option" :value="option">{{ option }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'VSearchBar',
  data () {
    return {
      category: ['Supermercado', 'Farmácia', 'Restaurante']
    }
  },
  computed: {
    options () {
      return this.category
    }
  }
}
</script>

<style lang="scss" scoped>
.search-bar {
  max-width: 100%;
  width: 500px;
  height: 50px;
  background-color: #ffff;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  input {
    width: 100%;
    border: none;
    outline: none;
  }
  .pipe {
    width: 2px;
    height: 25px;
    background-color: #E5E5E5;
  }
  select {
    margin-left: 20px;
    border: none;
    background-color: #ffff;
    outline: none;
    color: $dark-blue;
    font-size: 12px;
  }
}
</style>
