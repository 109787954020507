<template>
  <div class="v-club-card pb-3">
    <img src="https://picsum.photos/seed/picsum/280/190" alt="">
    <div class="m-3">
      <h3>ExtraPlus</h3>
      <h2>30% de desconto na caixa de Heineken</h2>
    </div>
    <span class="text-muted mx-3 mb-2"><i class="fal fa-map-marker-alt"></i> Vitória-ES</span>
  </div>
</template>

<script>
export default {
  name: 'VClubCard'
}
</script>

<style lang="scss" scoped>
.v-club-card {
  border-radius: $border-radius;
  border: 1px solid #E5E5E5;
  img {
    width: 100%;
    border-radius: 6px 6px 0px 0px;
  }
  h3 {
    font-size: 22px;
    font-weight: 700;
    color: $pink;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  h2 {
    font-size: 22px;
    font-weight: 700;
    color: $dark-blue;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  span {
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}
</style>
