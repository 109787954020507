<template>
  <div class="card d-none d-md-block">
    <div class="tilted"></div>
    <div class="content p-4 d-flex flex-column justify-content-around">
      <img src="@/assets/images/logo-jornal.png" alt="A Tribuna logo">
      <h2>Ainda não faz parte do clube?</h2>
      <p class="text-muted">
        Assine qualquer plano de
        <strong> A Tribuna </strong>
        e faça parte do clube que dá ótimos descontos e ofertas em todo Espírito Santo
      </p>
      <div>
        <router-link :to="{name: 'Checkout'}">
          <button type="button" class="cta btn btn-primary btn-sm px-4"> assinar </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VClubCTA'
}
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  width: 260px;
  height: 320px;
  background: none;
  border: none;
  .tilted {
    position: absolute;
    width: 260px;
    height: 320px;
    background: #ffffff;
    box-shadow: $box-shadow;
    border-radius: $border-radius-lg;
    transform: rotate(-10deg);
    z-index: -1;
  }
  .content {
    width: 260px;
    height: 320px;
    background: #ffffff;
    box-shadow: $box-shadow;
    border-radius: $border-radius-lg;
    img {
      width: 80px;
    }
    h2 {
      font-weight: 700;
      font-size: 26px;
      color: $dark-blue
    }
    p {
      font-size: 14px;
    }
    .cta {
      border: none;
      background-color: $pink;
      border-radius: $border-radius;
    }
  }
}

</style>
